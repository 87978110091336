(() => {
    if ('modules' in $ && 'language' in $.modules) {
        return;
    }

    /**
     * Creates a new Language class.
     * @class
     */
    const Language = function() {
        const self = this;

        /**
         * Add events listeners
         */
        self.setupListener = () => {
            $('.language__link').on('click', self.chooseLanguage);
        };

        /**
         * Choose language
         * @param {Object} e
         */
        self.chooseLanguage = function(e) {
            e.preventDefault();
            let $this = $(this);

            if ($(window).width() <= '783') {

                if (!$(this).hasClass('language__link_active')) {
                    $this.addClass('language__link_active');
                    $('.language__choice').addClass('language__choice_active');
                } else {
                    $this.removeClass('language__link_active');
                    $('.language__choice').removeClass('language__choice_active');
                }
            }

        };

        /**
         * Init module
         */
        self.init = () => {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.language = new Language();
})();
