(() => {
    if ('modules' in $ && 'team' in $.modules) {
        return;
    }

    /**
     * Creates a new Team class.
     * @class
     */
    const Team = function() {
        const self = this;

        /**
         * Add events listeners
         */
        self.setupListener = () => {
            $(window).on('resize', self.animateTeamTablet);
            $('.js-team').on('click', self.openPopUp);
        };

        /**
         * Animate Team-Tablet
         */
        self.animateTeamTablet = function() {
            let owl = $('.team-tablet__list');

            if ($(window).width() <= '783') {
                owl.addClass('owl-carousel').addClass('.owl-theme');
                owl.owlCarousel({
                    items: 1,
                    smartSpeed: 300,
                    mouseDrag: false,
                    animateIn: 'fadeIn',
                    // animateOut: 'fadeOut',
                    responsive: {
                        642: {
                            items: 3,
                            margin: 9,
                            animateOut: 'fadeOut'
                        }
                    }
                });
            } else {
                if (owl.hasClass('owl-carousel')) {
                    owl.removeClass('owl-carousel').removeClass('.owl-theme');
                    owl.trigger('destroy.owl.carousel');
                }
            }
        };

        /**
         * Opens Team-Pop-Up
         * @param {Object} e
         */
        self.openPopUp = function(e) {
            e.preventDefault();
            let $this = $(this);
            let personData = $this.data('popup');
            let popup = $('.team').find('.pop-up__content-inner');
            let popupWindow = $('.team').find('.pop-up');
            /*eslint-disable new-cap*/ /*no errors when task gulp defult run*/
            let popUpFadeOut = $.Deferred();
            /*eslint-enable new-cap*/

            const name = `<div class="pop-up__title">${personData.name}</div>`;
            const textcontent = personData.paragraphs.map(paragraph => {
                let text;

                if (paragraph.text) {
                    text = `<div class="pop-up__block"><div class="pop-up__text">${paragraph.text}</div></div>`;
                } else {
                    text = `<div></div>`;
                }

                return text;
            }).join('');
            const contentString = name + textcontent;

            popup.html(contentString);
            popupWindow.fadeIn(150).addClass('opened');

            if (popupWindow.hasClass('opened')) {
                $(document).on('click', function(e) {
                    e.preventDefault();
                    let $this = $(e.target);

                    if (!$this.closest('.pop-up__content').length) {
                        $this.closest('.pop-up').removeClass('opened').fadeOut(150, function() {
                            popUpFadeOut.resolve();
                        });
                        popUpFadeOut.done(function() {
                            $(document).off('click');
                        });
                    }
                });
            }
        };

        self.init = () => {
            self.animateTeamTablet();
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.team = new Team();
})();
