(() => {
    if ('modules' in $ && 'header' in $.modules) {
        return;
    }

    /**
     * Creates a new Header class.
     * @class
     */
    const Header = function() {
        const self = this;

        /**
         * Add events listeners
         */
        self.setupListener = () => {
            $(window).on('scroll', self.fixHeader);
            $(window).on('resize', self.showHeaderMenu);
            $('.burger-menu__link').on('click', self.burgerMenu);
            $('.header__image').on('click', self.goToTop);
        };

        /**
         * Fixes header
         */
        self.fixHeader = function() {
            let header = $('.header');
            let navExplain = $('.nav__buttons-explain');

            if ($(window).scrollTop() >= 450) {
                header.addClass('header_prefixed');
                if ($(window).scrollTop() >= 555) {
                    header.addClass('header_fixed');
                    navExplain.addClass('nav__buttons-explain_dark');
                } else {
                    header.removeClass('header_fixed');
                    navExplain.removeClass('nav__buttons-explain_dark');
                }
            } else {
                header.removeClass('header_prefixed').removeClass('header_fixed');
            }

        };

        /**
         * Shows HeaderMenu
         */
        self.showHeaderMenu = function() {
            let headerMenu = $('.header__block_menu');

            if ($(window).width() >= '751') {
                headerMenu.addClass('header__block_menu_desktop');
            } else {
                headerMenu.removeClass('header__block_menu_desktop');
            }
        };

        /**
         * Shows burger-menu
         * @param {Object} e
         */
        self.burgerMenu = function(e) {
            e.preventDefault();

            if ($(this).hasClass('burger-menu__link_active')) {
                $(this).removeClass('burger-menu__link_active');
                $('.header').removeClass('header_burger-fixed');
                $('.header__block_menu').fadeOut(200).removeClass('header__block_menu_fixed');
            } else {
                $(this).addClass('burger-menu__link_active');
                $('.header').addClass('header_burger-fixed');
                $('.header__block_menu').fadeIn(200).addClass('header__block_menu_fixed');
            }
        };

        /**
         * Go to Top
         * @param {Object} e
         */
        self.goToTop = function(e) {
            e.preventDefault();

            $('body, html').animate({scrollTop: 0}, 600);
            $('.header').removeClass('header_burger-fixed');
            $('.burger-menu__link').removeClass('burger-menu__link_active');

            if ($(window).width() <= '750') {
                $('.header__block_menu').fadeOut(400);
            }
        };

        /**
         * Init module
         */
        self.init = () => {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.header = new Header();
})();
