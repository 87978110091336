(() => {
    if ('modules' in $ && 'slider' in $.modules) {
        return;
    }

    /**
     * Creates a new Slider class.
     * @class
     */
    const Slider = function() {
        const self = this;

        /**
         * Add events listeners
         */
        self.setupListener = () => {
            $('.slider__link').on('click', self.goToContacts);
        };

        /**
         * Start Slider
         */
        self.startSlider = function() {
            let owl = $('.slider__list');

            owl.addClass('owl-carousel').addClass('owl-theme');
            owl.owlCarousel({
                items: 1,
                dots: true,
                slideSpeed: 600,
                rewindSpeed: 10,
                mouseDrag: false,
                autoplay: true,
                autoplayTimeout: 8000,
                loop: true,
                animateOut: 'fadeOut',
                animateIn: 'fadeIn',
                responsive: {
                    642: {
                        dots: false
                    }
                }
            });
            $('.slider__controls-link-left').on('click', function() {
                owl.trigger('prev.owl.carousel');
            });
            $('.slider__controls-link-right').on('click', function() {
                owl.trigger('next.owl.carousel');
            });
        };

        /**
         * Goes to section Contacts
         * @param {Object} e
         */
        self.goToContacts = function(e) {
            e.preventDefault();
            let reqPos = $('.contacts').offset().top;

            $('body, html').animate({scrollTop: reqPos}, 600);
        };

        self.init = () => {
            self.startSlider();
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.slider = new Slider();
})();
