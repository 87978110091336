(() => {
    if ('modules' in $ && 'contacts' in $.modules) {
        return;
    }

    /**
     * Creates a new Contacts class.
     * @class
     */
    const Contacts = function() {
        const self = this;
        const subscribeForm = $('#subscribe-form');
        const enquiryForm = $('#enquiry-form');

        /**
         * Add events listeners
         */
        self.setupListener = () => {
            $('.form__element').on('focus', self.checkForm);
            $('#subscribe-form').on('submit', self.activateSubscribeForm);
            $('#enquiry-form').on('submit', self.activateEnquiryForm);
        };

        /**
         * Active form-elements
         * @param {Object} event
         */
        self.checkForm = function() {
            let $this = $(this);

            $this.removeClass('input-alert');
            $this.removeClass('success');
        };

        /**
         * Activate SubscribeForm
         * @param {Object} e
         */
        self.activateSubscribeForm = function(e) {
            e.preventDefault();
            self.clearMessagesAndInputStyles(subscribeForm);
            self.prepareSendMail(subscribeForm);
        };

        /**
         * Activate EnquiryForm
         * @param {Object} e
         */
        self.activateEnquiryForm = function(e) {
            e.preventDefault();
            self.clearMessagesAndInputStyles(enquiryForm);
            self.prepareSendMail2(enquiryForm);
        };

        /**
         * Clear Styles
         * @param {Object} form
         */
        self.clearMessagesAndInputStyles = function(form) {
            form.find('.form__element').each(function() {
                $(this).removeClass('input-alert');
            });
        };

        /**
         * Check if the inputs of Subscribe Form filled
         * @param {Object} form
         */
        self.prepareSendMail = function(form) {
            let data = {
                email: form.find('#sendEmail').val().trim()
            };

            if (!data.email) {
                self.populateAndHighlightEmptyInputs(subscribeForm);
            } else {
                self.checkEmail(subscribeForm);
            }
        };

        /**
         * Check if the inputs of Enquiry Form filled
         * @param {Object} form
         */
        self.prepareSendMail2 = function(form) {
            let data = {
                name: form.find('#sendName').val().trim(),
                email: form.find('#sendEmail').val().trim(),
                text: form.find('#sendText').val().trim()
            };

            if (!data.name || !data.email || !data.text) {
                self.populateAndHighlightEmptyInputs(enquiryForm);
            } else {
                self.checkEmail(enquiryForm);
            }
        };

        /**
         * Choose Empty Inputs
         * @param {Object} form
         */
        self.populateAndHighlightEmptyInputs = function(form) {
            let emptyInputs = [];

            form.find('.form__element').each(function() {
                if (!$(this).val().trim()) {
                    emptyInputs.push($(this));
                } else {
                    $(this).addClass('success');
                }
            });

            self.highlightEmptyInputs(emptyInputs);
        };

        /**
         * Highlight Empty Inputs
         * @param {Object} emptyInputs
         */
        self.highlightEmptyInputs = function(emptyInputs) {

            emptyInputs.forEach(function(entry) {
                $(entry).addClass('input-alert');
            });

        };

        /**Check E-mail in form
         * @param {Object} form
         */
        self.checkEmail = function(form) {
            let pattern = /^[a-z0-9]+[_a-z0-9.-]*[a-z0-9]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
            let mail = form.find('#sendEmail');

            if (mail.val().search(pattern) === 0) {
                self.sendEmail(form);
            } else {
                form.find('.form__element').addClass('success');
                mail.addClass('input-alert');
            }
        };

        /**Send form through Ajax, clear inputs after sending and show success-message
         * @param {Object} form
         */
        self.sendEmail = function(form) {
            $.ajax({
                url: form.attr('action'),
                type: form.attr('method'),
                data: form.serialize(),
                success: function(data) {
                    let popup = data.status ? "#success-message" : "#error-message";
                    let message = form.find(popup);

                    form.each(function() {
                        this.reset();
                    });
                    form.find('.form__element').removeClass('success');
                    form.addClass('show-message-success');
                    message.addClass('show-message-success');
                    let timer = setTimeout(function() {
                        form.removeClass('show-message-success');
                        form.find('.form-message').removeClass('show-message-success');
                    }, 10000);

                    timer;
                    $('.form-message__link').on('click', function(e) {
                        e.preventDefault();
                        let $that = $(this);

                        self.showForm($that, timer);
                    });
                }
            });
        };

        /**
         * Shows form after sending if the user wants
         * @param {Object} that, timer
         */
        self.showForm = function(that, timer) {
            let $this = that;
            let form = $this.closest('form');
            let message = $this.closest('.form-message');

            form.removeClass('show-message-success');
            message.removeClass('show-message-success');
            clearTimeout(timer);
        };

        /**
         * Init module
         */
        self.init = () => {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.contacts = new Contacts();
})();
