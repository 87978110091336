(() => {
    if ('modules' in $ && 'offices' in $.modules) {
        return;
    }

    /**
     * Creates a new Offices class.
     * @class
     */
    const Offices = function() {
        const self = this;

        /**
         * Insert Map to the page
         */
        self.insertMap = function() {

            let styleArray=[
                {
                    featureType: 'all',
                    elementType: 'geometry.fill',
                    stylers: [{color: '#00a6e4'}]
                },
                {
                    featureType: 'all',
                    elementType: 'labels',
                    stylers: [{color: '#ffffff'}]
                },
                {
                    featureType: 'all',
                    elementType: 'labels.text.fill',
                    stylers: [{visibility: 'off'}]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{visibility: 'on'}]
                },
                {
                    featureType: 'landscape.man_made',
                    elementType: 'geometry.stroke',
                    stylers: [{color: '#ffffff'}]
                },
                {
                    featureType: 'landscape.natural',
                    stylers: [{color: '#0083c6'}]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{color: '#073D8E'}]
                },
                {
                    featureType: 'road',
                    elementType: 'labels.icon',
                    stylers: [{visibility: 'off'}]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.icon',
                    stylers: [{visibility: 'off'}]
                },
                {
                    featureType: 'transit',
                    elementType: 'labels.icon',
                    stylers: [{visibility: 'off'}]
                },
                {
                    featureType: 'administrative',
                    elementType: 'geometry.stroke',
                    stylers: [{color: '#00a6e4'}]
                },
                {
                    featureType: 'administrative.province',
                    stylers: [{color: '#0083c6'}]
                },
                {
                    featureType: 'administrative.land_parcel',
                    stylers: [{color: '#000000'}]
                }
            ];

            /*eslint-disable no-unused-vars*/ /*no errors when task gulp defult run*/
            let map = new google.maps.Map(document.getElementById('map'), {
                center: {lat: 35.501, lng: 2.775},
                styles: styleArray,
                disableDefaultUI: true,
                zoomControl: true,
                zoom: 3
            });
            /*eslint-enable no-unused-vars*/

            let markers = $('.offices').data('markers');
            let image = {
                url: '../images/pic-map.png'
            };
            let markersArray = [];
            let infoWindowArray = [];

            for (let i = 0; i < markers.length; i++) {
                let marker = new google.maps.Marker({
                    position: {lat: markers[i].lat, lng: markers[i].lng},
                    map: map,
                    animation: google.maps.Animation.DROP,
                    icon: image
                });
                const title = `<div id="firstHeading" class="map-info__title">${markers[i].title}</div><ul class="map-info__list">`;
                const address = `<li class="map-info__item"><div class="map-info__image"><img class="map-info__pic" src="/images/pic-info-address.png"></div><div class="map-info__text map-info__address">${markers[i].address}</div></li>`;
                const person = markers[i].persons.map(person => {
                    let email;
                    let phone;
                    let site;
                    let string;

                    if (person.email) {
                        email = `<li class="map-info__item"><div class="map-info__image"><img class="map-info__pic" src="/images/pic-info-mail.png"></div><div class="map-info__text"><a class="map-info__link" target=_blank href="mailto:${person.email}">${person.name}</a></div></li>`;
                    } else {
                        email = `<div></div>`;
                    }

                    if (person.site) {
                        site = `<li class="map-info__item"><div class="map-info__image"><img src="/images/pic-info-site.png" class="map-info__pic"></img></div><div class="map-info__text"><a class="map-info__link" href="https://${person.site}" target=_blank>${person.site}</a></div></li>`;
                    } else {
                        site = `<div></div>`;
                    }

                    if (person.phones.length) {
                        phone = '';
                        for (let i = 0; i < person.phones.length; i++) {
                            phone += `<li class="map-info__item"><div class="map-info__image"><img src="/images/pic-info-phone.png" class="map-info__pic"></img></div><div class="map-info__text"><div class="map-info__text-desktop">${person.phones[i]}</div><a class="map-info__link map-info__phone" href="tel:${person.phones[i]}">${person.phones[i]}</a></div></li>`;
                        }
                    } else {
                        phone = `<div></div>`;
                    }

                    string = email + phone + site;

                    return string;
                }).join('');
                const add = '</ul>';
                const contentString = title + address + person + add;
                const infoWindow = new google.maps.InfoWindow({
                    content: contentString
                });

                markersArray.push(marker);
                infoWindowArray.push(infoWindow);

                marker.addListener('click', function() {
                    for (let i = 0; i < markersArray.length; i++) {
                        infoWindowArray[i].close(map, markersArray[i]);
                    }
                    infoWindow.open(map, marker);
                });
            }
        };

        /**
         * Init module
         */
        self.init = () => {
            self.insertMap();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.offices = new Offices();
})();
