(() => {
    if ('modules' in $ && 'affiliates' in $.modules) {
        return;
    }

    /**
     * Creates a new Affiliates class.
     * @class
     */
    const Affiliates = function() {
        const self = this;

        /**
         * Add events listeners
         */
        self.setupListener = () => {
            $(window).on('resize', self.animateTabsMobile);
            $(window).on('resize', self.animateTabs);
            $('.affiliates__controls-link').on('click', self.toggleTabs);
            $('.affiliates__accordeon-trigger').on('click', self.showAccordeonItem);
            $(window).on('scroll', self.firstAnimation);
            $(window).on('resize', self.firstAnimation);
        };

        /**
         * Toggle Affiliates-Tabs
         * @param {Object} e
         */
        self.toggleTabs = function(e) {
            e.preventDefault();
            let $this=$(this);
            let item = $this.closest('.affiliates__controls-item');

            item
                .addClass('tabs__controls-item_active')
                .siblings()
                .removeClass('tabs__controls-item_active');
        };

        /**
         * Animate Affiliates-Tabs
         */
        self.animateTabs = function() {
            let owl = $('.affiliates__list');

            if ($(window).width() >= '624') {
                owl.addClass('owl-carousel').addClass('owl-theme');
                owl.owlCarousel({
                    items: 1,
                    dots: false,
                    animateOut: 'fadeOut',
                    animateIn: 'fadeIn',
                    mouseDrag: false,
                    touchDrag: false,
                    smartSpeed: 250
                });

                $('.affiliates-controls__link-0').on('click', function() {
                    owl.trigger('to.owl.carousel', [0]);
                });
                $('.affiliates-controls__link-1').on('click', function() {
                    owl.trigger('to.owl.carousel', [1]);
                });
                $('.affiliates-controls__link-2').on('click', function() {
                    owl.trigger('to.owl.carousel', [2]);
                });
                $('.affiliates-controls__link-3').on('click', function() {
                    owl.trigger('to.owl.carousel', [3]);
                });
            } else {
                owl.removeClass('owl-carousel owl-theme');
            }
        };

        /**
         * Animate affiliates-partners-Mobile
         */
        self.animateTabsMobile = function() {
            if ($(window).width() <= '623') {
                let owl = $('.affiliates__partners-tablet');

                owl.addClass('owl-carousel').addClass('owl-theme');
                owl.owlCarousel({
                    items: 1,
                    animateIn: 'fadeIn',
                    loop: true,
                    smartSpeed: 400,
                    mouseDrag: false
                });
            }
        };

        /**
         * First Animation
         */
        self.firstAnimation = function() {
            if ($(window).width() >= '624') {
                if (($('.affiliates').offset().top - $(window).scrollTop()) <= 380) {
                    $('.affiliates').find('.animation-item').removeClass('zoomOut').addClass('animated zoomIn');
                }
            } else {
                $('.affiliates').find('.animation-item').removeClass('animation zoomOut');
            }
        };

        /**
         * Show Affiliates-Accordeon-Items
         * @param {Object} e
         */
        self.showAccordeonItem = function(e) {
            e.preventDefault();
            let duration = 300;
            let $this = $(this);
            let item = $this.closest('.affiliates__item');
            let list = $this.closest('.affiliates__list-tablet');
            let otherTriggers = list.find('.accordeon__trigger');
            let content = item.find('.affiliates__partners-tablet');
            let otherContent = list.find('.affiliates__partners-tablet');

            if (!$this.hasClass('accordeon__trigger_active')) {
                otherTriggers.removeClass('accordeon__trigger_active');
                $this.addClass('accordeon__trigger_active');
                otherContent.stop(true, true).slideUp(duration);
                content.stop(true, true).slideDown(duration);
            } else {
                $this.removeClass('accordeon__trigger_active');
                content.stop(true, true).slideUp(duration);
            }
        };

        /**
         * Init module
         */
        self.init = () => {
            self.animateTabs();
            self.animateTabsMobile();
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.affiliates = new Affiliates();
})();
