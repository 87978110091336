(() => {
    if ('modules' in $ && 'about' in $.modules) {
        return;
    }

    /**
     * Creates a new About class.
     * @class
     */
    const About = function() {
        const self = this;

        /**
         * Add events listeners
         */
        self.setupListener = () => {
            $('.about__controls-link').on('click', self.toggleTabs);
            $('.about__accordeon-trigger').on('click', self.showAccordeonItem);
            $(window).on('resize', self.animateTabs);
            $(window).on('resize', self.animateTabsMobile);
        };

        /**
         * Toggle About-Tabs
         * @param {Object} e
         */
        self.toggleTabs = function(e) {
            e.preventDefault();
            let $this=$(this);
            let item = $this.closest('.about__controls-item');

            item
                .addClass('tabs__controls-item_active')
                .siblings()
                .removeClass('tabs__controls-item_active');
        };

        /**
         * Animate About-Tabs
         */
        self.animateTabs = function() {
            let owl = $('.about__description-list');

            if ($(window).width() >= '624') {
                owl.addClass('owl-carousel').addClass('owl-theme');
                owl.owlCarousel({
                    items: 1,
                    dots: false,
                    smartSpeed: 250,
                    mouseDrag: false,
                    touchDrag: false,
                    autoHeight: true,
                    animateIn: 'fadeIn',
                    animateOut: 'fadeOut'
                });
                $('.about-controls__link-0').on('click', function() {
                    owl.trigger('to.owl.carousel', [0]);
                });
                $('.about-controls__link-1').on('click', function() {
                    owl.trigger('to.owl.carousel', [1]);
                });
                $('.about-controls__link-2').on('click', function() {
                    owl.trigger('to.owl.carousel', [2]);
                });
            } else {
                owl.removeClass('owl-carousel owl-theme');
            }
        };

        /**
         * Animate About-desc-list-Mobile
         */
        self.animateTabsMobile = function() {
            if ($(window).width() <= '623') {
                let owl = $('.about__desc-list-tablet');

                owl.addClass('owl-carousel').addClass('owl-theme');
                owl.owlCarousel({
                    items: 1,
                    smartSpeed: 250,
                    loop: true,
                    autoplayHoverPause: true,
                    autoplay: true,
                    autoplayTimeout: 7000,
                    mouseDrag: false,
                    animateOut: 'fadeOut'
                });
            }
        };

        /**
         * Show About-Accordeon-Items
         * @param {Object} e
         */
        self.showAccordeonItem = function(e) {
            e.preventDefault();
            let duration = 300;
            let $this = $(this);
            let item = $this.closest('.about__description-item');
            let list = $this.closest('.about__description-list-tablet');
            let otherTriggers = list.find('.accordeon__trigger');
            let content = item.find('.about__description-item-inner');
            let otherContent = list.find('.about__description-item-inner');

            if (!$this.hasClass('accordeon__trigger_active')) {
                otherTriggers.removeClass('accordeon__trigger_active');
                $this.addClass('accordeon__trigger_active');
                otherContent.stop(true, true).slideUp(duration);
                content.stop(true, true).slideDown(duration);
            } else {
                $this.removeClass('accordeon__trigger_active');
                content.stop(true, true).slideUp(duration);
            }
        };

        /**
         * Init module
         */
        self.init = () => {
            self.animateTabs();
            self.animateTabsMobile();
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.about = new About();
})();
