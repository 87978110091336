(() => {
    if ('modules' in $ && 'footer' in $.modules) {
        return;
    }

    /**
     * Creates a new Footer class.
     * @class
     */
    const Footer = function() {
        const self = this;

        /**
         * Add events listeners
         */
        self.setupListener = () => {
            $('.js-footer').on('click', self.openPopUp);
        };

        /**
         * Opens Pop-Up
         * @param {Object} e
         */
        self.openPopUp = function(e) {
            e.preventDefault();
            let $this = $(this);
            let reqPopUp = $this.siblings('.pop-up');
            /*eslint-disable new-cap*/ /*no errors when task gulp defult run*/
            let popUpFadeOut = $.Deferred();
            /*eslint-enable new-cap*/

            reqPopUp.fadeIn(150).addClass('opened');
            if ($(window).width() >= '624') {
                $('body, html').scrollTop(0);
            }
            if ($('.pop-up').hasClass('opened')) {
                $(document).on('click', function(e) {
                    if (e.target.tagName !== 'A') {
                        e.preventDefault();
                    }

                    let $this = $(e.target);

                    if (!$this.closest('.pop-up__content').length) {
                        $this.closest('.pop-up').removeClass('opened').fadeOut(150, function() {
                            popUpFadeOut.resolve();
                        });
                        popUpFadeOut.done(function() {
                            $(document).off('click');
                            if ($(window).width() >= '624') {
                                $('body, html').scrollTop($(document).height());
                            }
                        });
                    }
                });
            }
        };

        /**
         * Init module
         */
        self.init = () => {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.footer = new Footer();
})();
