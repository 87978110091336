(() => {
    if ('modules' in $ && 'products' in $.modules) {
        return;
    }

    /**
     * Creates a new Prducts class.
     * @class
     */
    const Products = function() {
        const self = this;

        /**
         * Add events listeners
         */
        self.setupListener = () => {
            $('.products__button-link').on('click', self.openProducts);
            $('.js-products').on('click', self.openPopUp);
            $('.pop-up__close-button').on('click', self.closePopUp);
            $(window).on('keydown', self.closePopUpKeyBoard);
        };

        /**
         * Show More Products on Mobils
         * @param {Object} e
         */
        self.openProducts = function(e) {
            e.preventDefault();
            let $this = $(this);
            let item = $('.products__item_absolute');
            let items = $('.products__list_second');

            item.addClass('active').slideDown('slow');
            items.slideDown('slow');
            $this.closest('.products__button').addClass('products__button_clicked');
        };

        /**
         * Opens Pop-Up
         * @param {Object} e
         */
        self.openPopUp = function(e) {
            e.preventDefault();
            let $this = $(this);
            let reqPopUp = $this.siblings('.pop-up');
            /*eslint-disable new-cap*/ /*no errors when task gulp defult run*/
            let popUpFadeOut = $.Deferred();
            /*eslint-enable new-cap*/

            reqPopUp.fadeIn(150).addClass('opened');
            if ($('.pop-up').hasClass('opened')) {
                $(document).on('click', function(e) {
                    e.preventDefault();
                    let $this = $(e.target);

                    if (!$this.closest('.pop-up__content').length) {
                        $this.closest('.pop-up').removeClass('opened').fadeOut(150, function() {
                            popUpFadeOut.resolve();
                        });
                        popUpFadeOut.done(function() {
                            $(document).off('click');
                        });
                    }
                });
            }
        };

        /**
         * Closes Pop-Up
         * @param {Object} e
         */
        self.closePopUp = function(e) {
            e.preventDefault();
            let $this = $(this);

            $this.closest('.pop-up').fadeOut(150).removeClass('opened');
            $(document).off('click');
            if ($this.hasClass('pop-up__close-button_footer') && $(window).width() >= '624') {
                $('body, html').scrollTop($(document).height());
            }
        };

        /**
         * Closes Pop-Up
         * @param {Object} e
         */
        self.closePopUpKeyBoard = function(e) {
            if ($('.pop-up.opened').length) {
                if (e.which === 27) {
                    $(document).off('click');
                    if ($('.pop-up.opened').hasClass('pop-up-footer') && $(window).width() >= '624') {
                        $('body, html').scrollTop($(document).height());
                    }
                    $('.pop-up.opened').fadeOut(150).removeClass('opened');
                }
            }
        };

        /**
         * Init module
         */
        self.init = () => {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.products = new Products();
})();
