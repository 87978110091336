'use strict';

/**
 * Init main module
 */
$(document).ready(function () {
    $.modules.main.init();
});

(function () {
    if ('modules' in $ && 'about' in $.modules) {
        return;
    }

    /**
     * Creates a new About class.
     * @class
     */
    var About = function About() {
        var self = this;

        /**
         * Add events listeners
         */
        self.setupListener = function () {
            $('.about__controls-link').on('click', self.toggleTabs);
            $('.about__accordeon-trigger').on('click', self.showAccordeonItem);
            $(window).on('resize', self.animateTabs);
            $(window).on('resize', self.animateTabsMobile);
        };

        /**
         * Toggle About-Tabs
         * @param {Object} e
         */
        self.toggleTabs = function (e) {
            e.preventDefault();
            var $this = $(this);
            var item = $this.closest('.about__controls-item');

            item.addClass('tabs__controls-item_active').siblings().removeClass('tabs__controls-item_active');
        };

        /**
         * Animate About-Tabs
         */
        self.animateTabs = function () {
            var owl = $('.about__description-list');

            if ($(window).width() >= '624') {
                owl.addClass('owl-carousel').addClass('owl-theme');
                owl.owlCarousel({
                    items: 1,
                    dots: false,
                    smartSpeed: 250,
                    mouseDrag: false,
                    touchDrag: false,
                    autoHeight: true,
                    animateIn: 'fadeIn',
                    animateOut: 'fadeOut'
                });
                $('.about-controls__link-0').on('click', function () {
                    owl.trigger('to.owl.carousel', [0]);
                });
                $('.about-controls__link-1').on('click', function () {
                    owl.trigger('to.owl.carousel', [1]);
                });
                $('.about-controls__link-2').on('click', function () {
                    owl.trigger('to.owl.carousel', [2]);
                });
            } else {
                owl.removeClass('owl-carousel owl-theme');
            }
        };

        /**
         * Animate About-desc-list-Mobile
         */
        self.animateTabsMobile = function () {
            if ($(window).width() <= '623') {
                var owl = $('.about__desc-list-tablet');

                owl.addClass('owl-carousel').addClass('owl-theme');
                owl.owlCarousel({
                    items: 1,
                    smartSpeed: 250,
                    loop: true,
                    autoplayHoverPause: true,
                    autoplay: true,
                    autoplayTimeout: 7000,
                    mouseDrag: false,
                    animateOut: 'fadeOut'
                });
            }
        };

        /**
         * Show About-Accordeon-Items
         * @param {Object} e
         */
        self.showAccordeonItem = function (e) {
            e.preventDefault();
            var duration = 300;
            var $this = $(this);
            var item = $this.closest('.about__description-item');
            var list = $this.closest('.about__description-list-tablet');
            var otherTriggers = list.find('.accordeon__trigger');
            var content = item.find('.about__description-item-inner');
            var otherContent = list.find('.about__description-item-inner');

            if (!$this.hasClass('accordeon__trigger_active')) {
                otherTriggers.removeClass('accordeon__trigger_active');
                $this.addClass('accordeon__trigger_active');
                otherContent.stop(true, true).slideUp(duration);
                content.stop(true, true).slideDown(duration);
            } else {
                $this.removeClass('accordeon__trigger_active');
                content.stop(true, true).slideUp(duration);
            }
        };

        /**
         * Init module
         */
        self.init = function () {
            self.animateTabs();
            self.animateTabsMobile();
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.about = new About();
})();

(function () {
    if ('modules' in $ && 'affiliates' in $.modules) {
        return;
    }

    /**
     * Creates a new Affiliates class.
     * @class
     */
    var Affiliates = function Affiliates() {
        var self = this;

        /**
         * Add events listeners
         */
        self.setupListener = function () {
            $(window).on('resize', self.animateTabsMobile);
            $(window).on('resize', self.animateTabs);
            $('.affiliates__controls-link').on('click', self.toggleTabs);
            $('.affiliates__accordeon-trigger').on('click', self.showAccordeonItem);
            $(window).on('scroll', self.firstAnimation);
            $(window).on('resize', self.firstAnimation);
        };

        /**
         * Toggle Affiliates-Tabs
         * @param {Object} e
         */
        self.toggleTabs = function (e) {
            e.preventDefault();
            var $this = $(this);
            var item = $this.closest('.affiliates__controls-item');

            item.addClass('tabs__controls-item_active').siblings().removeClass('tabs__controls-item_active');
        };

        /**
         * Animate Affiliates-Tabs
         */
        self.animateTabs = function () {
            var owl = $('.affiliates__list');

            if ($(window).width() >= '624') {
                owl.addClass('owl-carousel').addClass('owl-theme');
                owl.owlCarousel({
                    items: 1,
                    dots: false,
                    animateOut: 'fadeOut',
                    animateIn: 'fadeIn',
                    mouseDrag: false,
                    touchDrag: false,
                    smartSpeed: 250
                });

                $('.affiliates-controls__link-0').on('click', function () {
                    owl.trigger('to.owl.carousel', [0]);
                });
                $('.affiliates-controls__link-1').on('click', function () {
                    owl.trigger('to.owl.carousel', [1]);
                });
                $('.affiliates-controls__link-2').on('click', function () {
                    owl.trigger('to.owl.carousel', [2]);
                });
                $('.affiliates-controls__link-3').on('click', function () {
                    owl.trigger('to.owl.carousel', [3]);
                });
            } else {
                owl.removeClass('owl-carousel owl-theme');
            }
        };

        /**
         * Animate affiliates-partners-Mobile
         */
        self.animateTabsMobile = function () {
            if ($(window).width() <= '623') {
                var owl = $('.affiliates__partners-tablet');

                owl.addClass('owl-carousel').addClass('owl-theme');
                owl.owlCarousel({
                    items: 1,
                    animateIn: 'fadeIn',
                    loop: true,
                    smartSpeed: 400,
                    mouseDrag: false
                });
            }
        };

        /**
         * First Animation
         */
        self.firstAnimation = function () {
            if ($(window).width() >= '624') {
                if ($('.affiliates').offset().top - $(window).scrollTop() <= 380) {
                    $('.affiliates').find('.animation-item').removeClass('zoomOut').addClass('animated zoomIn');
                }
            } else {
                $('.affiliates').find('.animation-item').removeClass('animation zoomOut');
            }
        };

        /**
         * Show Affiliates-Accordeon-Items
         * @param {Object} e
         */
        self.showAccordeonItem = function (e) {
            e.preventDefault();
            var duration = 300;
            var $this = $(this);
            var item = $this.closest('.affiliates__item');
            var list = $this.closest('.affiliates__list-tablet');
            var otherTriggers = list.find('.accordeon__trigger');
            var content = item.find('.affiliates__partners-tablet');
            var otherContent = list.find('.affiliates__partners-tablet');

            if (!$this.hasClass('accordeon__trigger_active')) {
                otherTriggers.removeClass('accordeon__trigger_active');
                $this.addClass('accordeon__trigger_active');
                otherContent.stop(true, true).slideUp(duration);
                content.stop(true, true).slideDown(duration);
            } else {
                $this.removeClass('accordeon__trigger_active');
                content.stop(true, true).slideUp(duration);
            }
        };

        /**
         * Init module
         */
        self.init = function () {
            self.animateTabs();
            self.animateTabsMobile();
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.affiliates = new Affiliates();
})();

(function () {
    if ('modules' in $ && 'contacts' in $.modules) {
        return;
    }

    /**
     * Creates a new Contacts class.
     * @class
     */
    var Contacts = function Contacts() {
        var self = this;
        var subscribeForm = $('#subscribe-form');
        var enquiryForm = $('#enquiry-form');

        /**
         * Add events listeners
         */
        self.setupListener = function () {
            $('.form__element').on('focus', self.checkForm);
            $('#subscribe-form').on('submit', self.activateSubscribeForm);
            $('#enquiry-form').on('submit', self.activateEnquiryForm);
        };

        /**
         * Active form-elements
         * @param {Object} event
         */
        self.checkForm = function () {
            var $this = $(this);

            $this.removeClass('input-alert');
            $this.removeClass('success');
        };

        /**
         * Activate SubscribeForm
         * @param {Object} e
         */
        self.activateSubscribeForm = function (e) {
            e.preventDefault();
            self.clearMessagesAndInputStyles(subscribeForm);
            self.prepareSendMail(subscribeForm);
        };

        /**
         * Activate EnquiryForm
         * @param {Object} e
         */
        self.activateEnquiryForm = function (e) {
            e.preventDefault();
            self.clearMessagesAndInputStyles(enquiryForm);
            self.prepareSendMail2(enquiryForm);
        };

        /**
         * Clear Styles
         * @param {Object} form
         */
        self.clearMessagesAndInputStyles = function (form) {
            form.find('.form__element').each(function () {
                $(this).removeClass('input-alert');
            });
        };

        /**
         * Check if the inputs of Subscribe Form filled
         * @param {Object} form
         */
        self.prepareSendMail = function (form) {
            var data = {
                email: form.find('#sendEmail').val().trim()
            };

            if (!data.email) {
                self.populateAndHighlightEmptyInputs(subscribeForm);
            } else {
                self.checkEmail(subscribeForm);
            }
        };

        /**
         * Check if the inputs of Enquiry Form filled
         * @param {Object} form
         */
        self.prepareSendMail2 = function (form) {
            var data = {
                name: form.find('#sendName').val().trim(),
                email: form.find('#sendEmail').val().trim(),
                text: form.find('#sendText').val().trim()
            };

            if (!data.name || !data.email || !data.text) {
                self.populateAndHighlightEmptyInputs(enquiryForm);
            } else {
                self.checkEmail(enquiryForm);
            }
        };

        /**
         * Choose Empty Inputs
         * @param {Object} form
         */
        self.populateAndHighlightEmptyInputs = function (form) {
            var emptyInputs = [];

            form.find('.form__element').each(function () {
                if (!$(this).val().trim()) {
                    emptyInputs.push($(this));
                } else {
                    $(this).addClass('success');
                }
            });

            self.highlightEmptyInputs(emptyInputs);
        };

        /**
         * Highlight Empty Inputs
         * @param {Object} emptyInputs
         */
        self.highlightEmptyInputs = function (emptyInputs) {

            emptyInputs.forEach(function (entry) {
                $(entry).addClass('input-alert');
            });
        };

        /**Check E-mail in form
         * @param {Object} form
         */
        self.checkEmail = function (form) {
            var pattern = /^[a-z0-9]+[_a-z0-9.-]*[a-z0-9]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
            var mail = form.find('#sendEmail');

            if (mail.val().search(pattern) === 0) {
                self.sendEmail(form);
            } else {
                form.find('.form__element').addClass('success');
                mail.addClass('input-alert');
            }
        };

        /**Send form through Ajax, clear inputs after sending and show success-message
         * @param {Object} form
         */
        self.sendEmail = function (form) {
            $.ajax({
                url: form.attr('action'),
                type: form.attr('method'),
                data: form.serialize(),
                success: function success(data) {
                    var popup = data.status ? "#success-message" : "#error-message";
                    var message = form.find(popup);

                    form.each(function () {
                        this.reset();
                    });
                    form.find('.form__element').removeClass('success');
                    form.addClass('show-message-success');
                    message.addClass('show-message-success');
                    var timer = setTimeout(function () {
                        form.removeClass('show-message-success');
                        form.find('.form-message').removeClass('show-message-success');
                    }, 10000);

                    timer;
                    $('.form-message__link').on('click', function (e) {
                        e.preventDefault();
                        var $that = $(this);

                        self.showForm($that, timer);
                    });
                }
            });
        };

        /**
         * Shows form after sending if the user wants
         * @param {Object} that, timer
         */
        self.showForm = function (that, timer) {
            var $this = that;
            var form = $this.closest('form');
            var message = $this.closest('.form-message');

            form.removeClass('show-message-success');
            message.removeClass('show-message-success');
            clearTimeout(timer);
        };

        /**
         * Init module
         */
        self.init = function () {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.contacts = new Contacts();
})();

(function () {
    if ('modules' in $ && 'footer' in $.modules) {
        return;
    }

    /**
     * Creates a new Footer class.
     * @class
     */
    var Footer = function Footer() {
        var self = this;

        /**
         * Add events listeners
         */
        self.setupListener = function () {
            $('.js-footer').on('click', self.openPopUp);
        };

        /**
         * Opens Pop-Up
         * @param {Object} e
         */
        self.openPopUp = function (e) {
            e.preventDefault();
            var $this = $(this);
            var reqPopUp = $this.siblings('.pop-up');
            /*eslint-disable new-cap*/ /*no errors when task gulp defult run*/
            var popUpFadeOut = $.Deferred();
            /*eslint-enable new-cap*/

            reqPopUp.fadeIn(150).addClass('opened');
            if ($(window).width() >= '624') {
                $('body, html').scrollTop(0);
            }
            if ($('.pop-up').hasClass('opened')) {
                $(document).on('click', function (e) {
                    if (e.target.tagName !== 'A') {
                        e.preventDefault();
                    }

                    var $this = $(e.target);

                    if (!$this.closest('.pop-up__content').length) {
                        $this.closest('.pop-up').removeClass('opened').fadeOut(150, function () {
                            popUpFadeOut.resolve();
                        });
                        popUpFadeOut.done(function () {
                            $(document).off('click');
                            if ($(window).width() >= '624') {
                                $('body, html').scrollTop($(document).height());
                            }
                        });
                    }
                });
            }
        };

        /**
         * Init module
         */
        self.init = function () {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.footer = new Footer();
})();

(function () {
    if ('modules' in $ && 'header' in $.modules) {
        return;
    }

    /**
     * Creates a new Header class.
     * @class
     */
    var Header = function Header() {
        var self = this;

        /**
         * Add events listeners
         */
        self.setupListener = function () {
            $(window).on('scroll', self.fixHeader);
            $(window).on('resize', self.showHeaderMenu);
            $('.burger-menu__link').on('click', self.burgerMenu);
            $('.header__image').on('click', self.goToTop);
        };

        /**
         * Fixes header
         */
        self.fixHeader = function () {
            var header = $('.header');
            var navExplain = $('.nav__buttons-explain');

            if ($(window).scrollTop() >= 450) {
                header.addClass('header_prefixed');
                if ($(window).scrollTop() >= 555) {
                    header.addClass('header_fixed');
                    navExplain.addClass('nav__buttons-explain_dark');
                } else {
                    header.removeClass('header_fixed');
                    navExplain.removeClass('nav__buttons-explain_dark');
                }
            } else {
                header.removeClass('header_prefixed').removeClass('header_fixed');
            }
        };

        /**
         * Shows HeaderMenu
         */
        self.showHeaderMenu = function () {
            var headerMenu = $('.header__block_menu');

            if ($(window).width() >= '751') {
                headerMenu.addClass('header__block_menu_desktop');
            } else {
                headerMenu.removeClass('header__block_menu_desktop');
            }
        };

        /**
         * Shows burger-menu
         * @param {Object} e
         */
        self.burgerMenu = function (e) {
            e.preventDefault();

            if ($(this).hasClass('burger-menu__link_active')) {
                $(this).removeClass('burger-menu__link_active');
                $('.header').removeClass('header_burger-fixed');
                $('.header__block_menu').fadeOut(200).removeClass('header__block_menu_fixed');
            } else {
                $(this).addClass('burger-menu__link_active');
                $('.header').addClass('header_burger-fixed');
                $('.header__block_menu').fadeIn(200).addClass('header__block_menu_fixed');
            }
        };

        /**
         * Go to Top
         * @param {Object} e
         */
        self.goToTop = function (e) {
            e.preventDefault();

            $('body, html').animate({ scrollTop: 0 }, 600);
            $('.header').removeClass('header_burger-fixed');
            $('.burger-menu__link').removeClass('burger-menu__link_active');

            if ($(window).width() <= '750') {
                $('.header__block_menu').fadeOut(400);
            }
        };

        /**
         * Init module
         */
        self.init = function () {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.header = new Header();
})();

(function () {
    if ('modules' in $ && 'language' in $.modules) {
        return;
    }

    /**
     * Creates a new Language class.
     * @class
     */
    var Language = function Language() {
        var self = this;

        /**
         * Add events listeners
         */
        self.setupListener = function () {
            $('.language__link').on('click', self.chooseLanguage);
        };

        /**
         * Choose language
         * @param {Object} e
         */
        self.chooseLanguage = function (e) {
            e.preventDefault();
            var $this = $(this);

            if ($(window).width() <= '783') {

                if (!$(this).hasClass('language__link_active')) {
                    $this.addClass('language__link_active');
                    $('.language__choice').addClass('language__choice_active');
                } else {
                    $this.removeClass('language__link_active');
                    $('.language__choice').removeClass('language__choice_active');
                }
            }
        };

        /**
         * Init module
         */
        self.init = function () {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.language = new Language();
})();

(function () {
    if ('modules' in $ && 'main' in $.modules) {
        return;
    }

    /**
     * Creates a new Main class.
     * @class
     */
    var Main = function Main() {
        var self = this;

        self.window = $(window);
        self.html = $('html');
        self.body = $('body');
        self.modules = $.modules;

        /**
         * Init all modules in $.modules
         */
        self.initModules = function () {
            for (var module in self.modules) {

                if (self.modules.hasOwnProperty(module)) {

                    if (typeof self.modules[module].init !== 'undefined' && module !== 'main') {

                        self.modules[module].init();
                    }
                }
            }
        };

        /**
         * Init module
         */
        self.init = function () {
            self.initModules();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.main = new Main();
})();

(function () {
    if ('modules' in $ && 'menu' in $.modules) {
        return;
    }

    /**
     * Creates a new Menu class.
     * @class
     */
    var Menu = function Menu() {
        var self = this;

        /**
         * Add events listeners
         */
        self.setupListener = function () {
            $('.menu__link').on('click', function (e) {
                e.preventDefault();
                self.showSection($(this).attr('href'), true);
                $('.header').removeClass('header_burger-fixed');
            });
            $('.nav__buttons-link').on('click', function (e) {
                e.preventDefault();
                self.showSection($(this).attr('href'), true);
            });
            $('.nav__buttons-explain-text').on('click', function (e) {
                e.preventDefault();
                self.showSection($(this).attr('href'), true);
            });

            if (window.location.hash) {
                self.showSection(window.location.hash, false);
            }

            $(window).on('scroll', self.checkSection);
        };

        /**
         * Show Section
         * @param {Object} section
         * @param {Object} isAnimate
         */
        self.showSection = function (section, isAnimate) {
            var direction = section.replace(/#/, '');
            var reqSection = $('.section').filter('[data-section="' + direction + '"]');
            var reqSectionPos = reqSection.offset().top - 68;

            if (isAnimate) {
                if ($('.header__block_menu').hasClass('header__block_menu_fixed')) {
                    $('.burger-menu__link').removeClass('burger-menu__link_active');
                    $('body').css('position', 'static');
                    $('.header__block_menu').fadeOut(400);
                }
                $('body, html').animate({ scrollTop: reqSectionPos }, 600);
            } else {
                $('body, html').scrollTop(reqSectionPos);
            }
        };

        /**
         * Check Section
         */
        self.checkSection = function () {
            $('section').each(function () {

                var $this = $(this);
                var topEdge = $this.offset().top - 250;
                var bottomEdge = topEdge + $this.height();
                var wScroll = $(window).scrollTop();

                if (topEdge < wScroll && bottomEdge > wScroll) {
                    var currentId = $this.data('section');
                    var reqLink = $('.menu__link').filter('[href="#' + currentId + '"]');
                    var reqButton = $('.nav__buttons-link').filter('[href="#' + currentId + '"]');
                    var reqButtonText = $('.nav__buttons-explain-text').filter('[href="#' + currentId + '"]');

                    reqLink.closest('.menu__item').addClass('menu__item_active').siblings().removeClass('menu__item_active');
                    $('.nav__buttons-link').removeClass('nav__buttons-link_active');
                    reqButton.addClass('nav__buttons-link_active');
                    reqButtonText.closest('.nav__buttons-explain-item').addClass('nav__buttons-explain-item_active').siblings().removeClass('nav__buttons-explain-item_active');

                    window.location.hash = currentId;
                }
            });
        };

        /**
         * Init module
         */
        self.init = function () {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.menu = new Menu();
})();

(function () {
    if ('modules' in $ && 'offices' in $.modules) {
        return;
    }

    /**
     * Creates a new Offices class.
     * @class
     */
    var Offices = function Offices() {
        var self = this;

        /**
         * Insert Map to the page
         */
        self.insertMap = function () {

            var styleArray = [{
                featureType: 'all',
                elementType: 'geometry.fill',
                stylers: [{ color: '#00a6e4' }]
            }, {
                featureType: 'all',
                elementType: 'labels',
                stylers: [{ color: '#ffffff' }]
            }, {
                featureType: 'all',
                elementType: 'labels.text.fill',
                stylers: [{ visibility: 'off' }]
            }, {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [{ visibility: 'on' }]
            }, {
                featureType: 'landscape.man_made',
                elementType: 'geometry.stroke',
                stylers: [{ color: '#ffffff' }]
            }, {
                featureType: 'landscape.natural',
                stylers: [{ color: '#0083c6' }]
            }, {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{ color: '#073D8E' }]
            }, {
                featureType: 'road',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }]
            }, {
                featureType: 'poi',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }]
            }, {
                featureType: 'transit',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }]
            }, {
                featureType: 'administrative',
                elementType: 'geometry.stroke',
                stylers: [{ color: '#00a6e4' }]
            }, {
                featureType: 'administrative.province',
                stylers: [{ color: '#0083c6' }]
            }, {
                featureType: 'administrative.land_parcel',
                stylers: [{ color: '#000000' }]
            }];

            /*eslint-disable no-unused-vars*/ /*no errors when task gulp defult run*/
            var map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: 35.501, lng: 2.775 },
                styles: styleArray,
                disableDefaultUI: true,
                zoomControl: true,
                zoom: 3
            });
            /*eslint-enable no-unused-vars*/

            var markers = $('.offices').data('markers');
            var image = {
                url: '../images/pic-map.png'
            };
            var markersArray = [];
            var infoWindowArray = [];

            var _loop = function _loop(i) {
                var marker = new google.maps.Marker({
                    position: { lat: markers[i].lat, lng: markers[i].lng },
                    map: map,
                    animation: google.maps.Animation.DROP,
                    icon: image
                });
                var title = '<div id="firstHeading" class="map-info__title">' + markers[i].title + '</div><ul class="map-info__list">';
                var address = '<li class="map-info__item"><div class="map-info__image"><img class="map-info__pic" src="/images/pic-info-address.png"></div><div class="map-info__text map-info__address">' + markers[i].address + '</div></li>';
                var person = markers[i].persons.map(function (person) {
                    var email = void 0;
                    var phone = void 0;
                    var site = void 0;
                    var string = void 0;

                    if (person.email) {
                        email = '<li class="map-info__item"><div class="map-info__image"><img class="map-info__pic" src="/images/pic-info-mail.png"></div><div class="map-info__text"><a class="map-info__link" target=_blank href="mailto:' + person.email + '">' + person.name + '</a></div></li>';
                    } else {
                        email = '<div></div>';
                    }

                    if (person.site) {
                        site = '<li class="map-info__item"><div class="map-info__image"><img src="/images/pic-info-site.png" class="map-info__pic"></img></div><div class="map-info__text"><a class="map-info__link" href="https://' + person.site + '" target=_blank>' + person.site + '</a></div></li>';
                    } else {
                        site = '<div></div>';
                    }

                    if (person.phones.length) {
                        phone = '';
                        for (var _i = 0; _i < person.phones.length; _i++) {
                            phone += '<li class="map-info__item"><div class="map-info__image"><img src="/images/pic-info-phone.png" class="map-info__pic"></img></div><div class="map-info__text"><div class="map-info__text-desktop">' + person.phones[_i] + '</div><a class="map-info__link map-info__phone" href="tel:' + person.phones[_i] + '">' + person.phones[_i] + '</a></div></li>';
                        }
                    } else {
                        phone = '<div></div>';
                    }

                    string = email + phone + site;

                    return string;
                }).join('');
                var add = '</ul>';
                var contentString = title + address + person + add;
                var infoWindow = new google.maps.InfoWindow({
                    content: contentString
                });

                markersArray.push(marker);
                infoWindowArray.push(infoWindow);

                marker.addListener('click', function () {
                    for (var _i2 = 0; _i2 < markersArray.length; _i2++) {
                        infoWindowArray[_i2].close(map, markersArray[_i2]);
                    }
                    infoWindow.open(map, marker);
                });
            };

            for (var i = 0; i < markers.length; i++) {
                _loop(i);
            }
        };

        /**
         * Init module
         */
        self.init = function () {
            self.insertMap();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.offices = new Offices();
})();

(function () {
    if ('modules' in $ && 'products' in $.modules) {
        return;
    }

    /**
     * Creates a new Prducts class.
     * @class
     */
    var Products = function Products() {
        var self = this;

        /**
         * Add events listeners
         */
        self.setupListener = function () {
            $('.products__button-link').on('click', self.openProducts);
            $('.js-products').on('click', self.openPopUp);
            $('.pop-up__close-button').on('click', self.closePopUp);
            $(window).on('keydown', self.closePopUpKeyBoard);
        };

        /**
         * Show More Products on Mobils
         * @param {Object} e
         */
        self.openProducts = function (e) {
            e.preventDefault();
            var $this = $(this);
            var item = $('.products__item_absolute');
            var items = $('.products__list_second');

            item.addClass('active').slideDown('slow');
            items.slideDown('slow');
            $this.closest('.products__button').addClass('products__button_clicked');
        };

        /**
         * Opens Pop-Up
         * @param {Object} e
         */
        self.openPopUp = function (e) {
            e.preventDefault();
            var $this = $(this);
            var reqPopUp = $this.siblings('.pop-up');
            /*eslint-disable new-cap*/ /*no errors when task gulp defult run*/
            var popUpFadeOut = $.Deferred();
            /*eslint-enable new-cap*/

            reqPopUp.fadeIn(150).addClass('opened');
            if ($('.pop-up').hasClass('opened')) {
                $(document).on('click', function (e) {
                    e.preventDefault();
                    var $this = $(e.target);

                    if (!$this.closest('.pop-up__content').length) {
                        $this.closest('.pop-up').removeClass('opened').fadeOut(150, function () {
                            popUpFadeOut.resolve();
                        });
                        popUpFadeOut.done(function () {
                            $(document).off('click');
                        });
                    }
                });
            }
        };

        /**
         * Closes Pop-Up
         * @param {Object} e
         */
        self.closePopUp = function (e) {
            e.preventDefault();
            var $this = $(this);

            $this.closest('.pop-up').fadeOut(150).removeClass('opened');
            $(document).off('click');
            if ($this.hasClass('pop-up__close-button_footer') && $(window).width() >= '624') {
                $('body, html').scrollTop($(document).height());
            }
        };

        /**
         * Closes Pop-Up
         * @param {Object} e
         */
        self.closePopUpKeyBoard = function (e) {
            if ($('.pop-up.opened').length) {
                if (e.which === 27) {
                    $(document).off('click');
                    if ($('.pop-up.opened').hasClass('pop-up-footer') && $(window).width() >= '624') {
                        $('body, html').scrollTop($(document).height());
                    }
                    $('.pop-up.opened').fadeOut(150).removeClass('opened');
                }
            }
        };

        /**
         * Init module
         */
        self.init = function () {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.products = new Products();
})();

(function () {
    if ('modules' in $ && 'slider' in $.modules) {
        return;
    }

    /**
     * Creates a new Slider class.
     * @class
     */
    var Slider = function Slider() {
        var self = this;

        /**
         * Add events listeners
         */
        self.setupListener = function () {
            $('.slider__link').on('click', self.goToContacts);
        };

        /**
         * Start Slider
         */
        self.startSlider = function () {
            var owl = $('.slider__list');

            owl.addClass('owl-carousel').addClass('owl-theme');
            owl.owlCarousel({
                items: 1,
                dots: true,
                slideSpeed: 600,
                rewindSpeed: 10,
                mouseDrag: false,
                autoplay: true,
                autoplayTimeout: 8000,
                loop: true,
                animateOut: 'fadeOut',
                animateIn: 'fadeIn',
                responsive: {
                    642: {
                        dots: false
                    }
                }
            });
            $('.slider__controls-link-left').on('click', function () {
                owl.trigger('prev.owl.carousel');
            });
            $('.slider__controls-link-right').on('click', function () {
                owl.trigger('next.owl.carousel');
            });
        };

        /**
         * Goes to section Contacts
         * @param {Object} e
         */
        self.goToContacts = function (e) {
            e.preventDefault();
            var reqPos = $('.contacts').offset().top;

            $('body, html').animate({ scrollTop: reqPos }, 600);
        };

        self.init = function () {
            self.startSlider();
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.slider = new Slider();
})();

(function () {
    if ('modules' in $ && 'team' in $.modules) {
        return;
    }

    /**
     * Creates a new Team class.
     * @class
     */
    var Team = function Team() {
        var self = this;

        /**
         * Add events listeners
         */
        self.setupListener = function () {
            $(window).on('resize', self.animateTeamTablet);
            $('.js-team').on('click', self.openPopUp);
        };

        /**
         * Animate Team-Tablet
         */
        self.animateTeamTablet = function () {
            var owl = $('.team-tablet__list');

            if ($(window).width() <= '783') {
                owl.addClass('owl-carousel').addClass('.owl-theme');
                owl.owlCarousel({
                    items: 1,
                    smartSpeed: 300,
                    mouseDrag: false,
                    animateIn: 'fadeIn',
                    // animateOut: 'fadeOut',
                    responsive: {
                        642: {
                            items: 3,
                            margin: 9,
                            animateOut: 'fadeOut'
                        }
                    }
                });
            } else {
                if (owl.hasClass('owl-carousel')) {
                    owl.removeClass('owl-carousel').removeClass('.owl-theme');
                    owl.trigger('destroy.owl.carousel');
                }
            }
        };

        /**
         * Opens Team-Pop-Up
         * @param {Object} e
         */
        self.openPopUp = function (e) {
            e.preventDefault();
            var $this = $(this);
            var personData = $this.data('popup');
            var popup = $('.team').find('.pop-up__content-inner');
            var popupWindow = $('.team').find('.pop-up');
            /*eslint-disable new-cap*/ /*no errors when task gulp defult run*/
            var popUpFadeOut = $.Deferred();
            /*eslint-enable new-cap*/

            var name = '<div class="pop-up__title">' + personData.name + '</div>';
            var textcontent = personData.paragraphs.map(function (paragraph) {
                var text = void 0;

                if (paragraph.text) {
                    text = '<div class="pop-up__block"><div class="pop-up__text">' + paragraph.text + '</div></div>';
                } else {
                    text = '<div></div>';
                }

                return text;
            }).join('');
            var contentString = name + textcontent;

            popup.html(contentString);
            popupWindow.fadeIn(150).addClass('opened');

            if (popupWindow.hasClass('opened')) {
                $(document).on('click', function (e) {
                    e.preventDefault();
                    var $this = $(e.target);

                    if (!$this.closest('.pop-up__content').length) {
                        $this.closest('.pop-up').removeClass('opened').fadeOut(150, function () {
                            popUpFadeOut.resolve();
                        });
                        popUpFadeOut.done(function () {
                            $(document).off('click');
                        });
                    }
                });
            }
        };

        self.init = function () {
            self.animateTeamTablet();
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.team = new Team();
})();