(() => {
    if ('modules' in $ && 'menu' in $.modules) {
        return;
    }

    /**
     * Creates a new Menu class.
     * @class
     */
    const Menu = function() {
        const self = this;

        /**
         * Add events listeners
         */
        self.setupListener = () => {
            $('.menu__link').on('click', function(e) {
                e.preventDefault();
                self.showSection($(this).attr('href'), true);
                $('.header').removeClass('header_burger-fixed');
            });
            $('.nav__buttons-link').on('click', function(e) {
                e.preventDefault();
                self.showSection($(this).attr('href'), true);
            });
            $('.nav__buttons-explain-text').on('click', function(e) {
                e.preventDefault();
                self.showSection($(this).attr('href'), true);
            });

            if (window.location.hash) {
                self.showSection(window.location.hash, false);
            }

            $(window).on('scroll', self.checkSection);
        };

        /**
         * Show Section
         * @param {Object} section
         * @param {Object} isAnimate
         */
        self.showSection = function(section, isAnimate) {
            let direction = section.replace(/#/, '');
            let reqSection = $('.section').filter('[data-section="' + direction + '"]');
            let reqSectionPos = reqSection.offset().top-68;

            if (isAnimate) {
                if ($('.header__block_menu').hasClass('header__block_menu_fixed')) {
                    $('.burger-menu__link').removeClass('burger-menu__link_active');
                    $('body').css('position', 'static');
                    $('.header__block_menu').fadeOut(400);
                }
                $('body, html').animate({scrollTop: reqSectionPos}, 600);
            } else {
                $('body, html').scrollTop(reqSectionPos);
            }
        };

        /**
         * Check Section
         */
        self.checkSection = function() {
            $('section').each(function() {

                let $this = $(this);
                let topEdge = $this.offset().top - 250;
                let bottomEdge = topEdge + $this.height();
                let wScroll = $(window).scrollTop();

                if (topEdge < wScroll && bottomEdge > wScroll) {
                    let currentId = $this.data('section');
                    let reqLink = $('.menu__link').filter('[href="#' + currentId + '"]');
                    let reqButton = $('.nav__buttons-link').filter('[href="#' + currentId + '"]');
                    let reqButtonText = $('.nav__buttons-explain-text').filter('[href="#' + currentId + '"]');

                    reqLink.closest('.menu__item').addClass('menu__item_active').siblings().removeClass('menu__item_active');
                    $('.nav__buttons-link').removeClass('nav__buttons-link_active');
                    reqButton.addClass('nav__buttons-link_active');
                    reqButtonText.closest('.nav__buttons-explain-item').addClass('nav__buttons-explain-item_active').siblings().removeClass('nav__buttons-explain-item_active');

                    window.location.hash = currentId;
                }
            });
        };

        /**
         * Init module
         */
        self.init = () => {
            self.setupListener();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.menu = new Menu();
})();
